.conteudo {
    width: 100%;
}

.containerGraduacao {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    row-gap: 20px;
    margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
    .containerGraduacao {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (min-width: 1224px) {
    .containerGraduacao {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media screen and (min-width: 1824px) {
    .containerGraduacao {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.Pesquisa{
    margin-top: 50px;
    text-align: center;
    margin-bottom: 50px;
}

.Filtro {
    margin-left: 10vw;
    margin-top: 4vh;
    margin-bottom: 2vh;
    margin-right: 3vw;
    font-family: 'Barlow';
    font-size: 14px;
    text-transform: uppercase;
    border-bottom: solid 2px #000000;
    width: 20vw;
}

.BotaoFiltro {
    background-color: #002555;
    border-radius: 5px;
    height: 2.5em;
    color: white;
    font-weight: 800;
    width: 10em;

}

.BotaoFiltro:active{
    transform: scale(0.94);
    transition: transform 0.2s ease;
}

.BotaoFiltro:hover{
    background-color: #061c38;
}