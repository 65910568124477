.wrap{
    display: flex;
}

.imagem{
    width: 320px;
    display: 1;
    margin-left: 3em;
    margin-right: 3em;
}

.caixa_textos{
    width: 100%;
    margin-right: 3em;
}

.branco {
    width: 100%;
    height: 10em;
    background-color: #002555;
}

@media screen and (max-width: 1000px){
    .wrap{
        display: block;
    }

    .branco {
        width: 0px;
        height: 0px;
    }

    .imagem{
        width: 320px;
        display: 1;
        margin:3em;
        margin-top: 0px;
        margin-left: 30%;
    }

    .caixa_textos{
        width: 100%;
        margin-right: 3em;
    }
}

@media screen and (max-width: 700px){
    .imagem{
        margin-left: 25%;
        margin-bottom: 2em;
    }
}

@media screen and (max-width: 600px){
    .imagem{
        margin-left: 20%;
        margin-bottom: 2em;
    }
}

@media screen and (max-width: 500px){
    .imagem{
        margin-left: 15%;
        margin-bottom: 2em;
    }
}

@media screen and (max-width: 400px){
    .imagem{
        margin-left: 10%;
        margin-bottom: 2em;
    }
}


@media screen and (max-width: 400px){
    .imagem{
        margin-left: 0.5em;
        margin-bottom: 0.8em;
    }
}