/*Created and developed exclusively by William Fagundes*/
#logoibmecmenu {
  visibility: hidden;
  margin: -1000%;
}
/*Created and developed exclusively by William Fagundes*/
#logoibmec {
  height: 3em;
  margin-right: 0%;
  margin: 15px;
  margin-left: 10em;
  cursor:pointer;
}
/*Created and developed exclusively by William Fagundes*/

@media screen and (max-width: 1000px){
  #logoibmecmenu {
    visibility: visible;
    margin: 0;
    margin-top: 30px;
    margin-left: -50px;
    height: 3em;
    cursor: pointer;
  }
  #logoibmec {
    height: 3em;
    margin-right: 0%;
    margin: 15px;
    margin-left: 40px;
    cursor:pointer;
  }
  .botao{
      margin-left: 40px;
  }
  body
  {
    font-family: "Avenir Next", "Avenir", sans-serif;
  }

  #menuToggle
  {
    display: block;
    position: relative;
    top: 30px;
    left: 50px;

    z-index: 2;

    -webkit-user-select: none;
    user-select: none;
  }
/*Created and developed exclusively by William Fagundes*/
  #menuToggle a
  {
    text-decoration: none;
    color: #232323;

    transition: color 0.3s ease;
  }

  #menuToggle a:hover
  {
    color: #F5AC00;
  }


  #menuToggle input
  {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;
    margin-left: 600px;
    cursor: pointer;

    opacity: 0;
    z-index: 3;

    -webkit-touch-callout: none;
  }
/*Created and developed exclusively by William Fagundes*/
  #menuToggle span
  {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    margin-left: 600px;
    position: relative;

    background: #F5AC00;
    border-radius: 3px;

    z-index: 2;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
  }

  #menuToggle span:first-child
  {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2)
  {
    transform-origin: 0% 100%;
  }
/*Created and developed exclusively by William Fagundes*/
  #menuToggle input:checked ~ span
  {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #F5AC00;
  }

  #menuToggle input:checked ~ span:nth-last-child(3)
  {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  #menuToggle input:checked ~ span:nth-last-child(2)
  {
    transform: rotate(-45deg) translate(0, -1px);
  }
/*Created and developed exclusively by William Fagundes*/
  #menu
  {
    position: absolute;
    width: 1000px;
    margin-left: -300px;
    margin-top: -70px;
    padding: 50px;
    padding-top: 125px;
    background: #ededed;
    cursor: pointer;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    font-family: 'Barlow';
      font-weight: 500;
      font-style: italic;
      font-size: 18px;
      border-radius: 0  8px  8px 0;
      text-decoration: none;
      color:white;

    transform-origin: 0% 0%;
    transform: translate(-100%, 0);

    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

  }
/*Created and developed exclusively by William Fagundes*/
  #menu li
  {
    padding: 10px 0;
    font-size: 22px;
  }

  #menuToggle input:checked ~ ul
  {
    transform: none;
  }
}

@media screen and (min-width: 1000px){
  #menuToggle {
    visibility : hidden;
  }

  #menu {
    visibility: visible;
    font-family: 'Barlow';
    width: 700px;
    font-weight: 500;
    font-style: italic;
    font-size: 18px;
    flex-grow: 1;
    padding: 0.8em;
    border-radius: 0  8px  8px 0;
    text-decoration: none;
    color:white;
    margin: 1%;
    margin-left: 10px;
    list-style-type: none;
    display: flex;
    margin-top: -10px;
  }
}
@media screen and (max-width: 920px){
  #menuToggle span{
    margin-left: 500px;
  }
  #menuToggle input{
    margin-left: 500px;
  }
}
@media screen and (max-width: 820px){
  #menuToggle span{
    margin-left: 400px;
  }
  #menuToggle input{
    margin-left: 400px;
  }
}
/*Created and developed exclusively by William Fagundes*/
@media screen and (max-width: 720px){
  #menuToggle span{
    margin-left: 300px;
  }
  #menuToggle input{
    margin-left: 300px;
  }
}
@media screen and (max-width: 620px){
  #menuToggle span{
    margin-left: 200px;
  }
  #menuToggle input{
    margin-left: 200px;
  }
}
@media screen and (max-width: 520px){
  #menuToggle span{
    margin-left: 100px;
  }
  #menuToggle input{
    margin-left: 100px;
  }
}
@media screen and (max-width: 420px){
  #menuToggle span{
    margin-left: 50px;
  }
  #menuToggle input{
    margin-left: 50px;
  }
}

/*Created and developed exclusively by William Fagundes*/
/*Created and developed exclusively by William Fagundes & Théo Furtado*/

@media screen and (min-width: 1000px){
  #edu:hover #dropdown-educacao{
    display: block;
  }
  #dropdown-educacao:hover #dropdown-educacao{
    display: block;
  }
  #dropdown-educacao{
    display: none;
    flex-direction: column;
    background-color: #002555;
    width: 300px;
    text-align: center;
    margin-left: 0px;
    list-style: none;
    height: 30px;
    margin-top: 0px;
    border-radius: 10px;
  }
  #dropdown-educacao a:hover {
    color: #F5AC00;
  }
}

/*Created and developed exclusively by William Fagundes & Théo Furtado*/

@media screen and (max-width: 1000px){
  #edu:hover #dropdown-educacao{
    display: block;
  }
  #dropdown-educacao:hover #dropdown-educacao{
    display: block;
  }
  #dropdown-educacao{
    display: none;
    width: 300px;
    border-radius: 15px;
    text-align: center;
    list-style: none;
    height: 30px;
    margin-top: 0px;
  }
  #dropdown-educacao a:hover {
    color: #F5AC00;
  }
}
/*Created and developed exclusively by William Fagundes & Théo Furtado*/

