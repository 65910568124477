

.containerAlunos {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    justify-items: center;
    column-gap: 1px;
    row-gap: 40px;
    justify-items: center;
}

@media screen and (max-width: 1900px) {
    .containerAlunos {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 1600px) {
    .containerAlunos {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 1320px) {
    .containerAlunos {
        grid-template-columns: 1fr 1fr 1fr 1fr ;
    }
}

@media screen and (max-width: 1080px) {
    .containerAlunos {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 830px) {
    .containerAlunos {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 560px) {
    .containerAlunos {
        grid-template-columns: 1fr;
    }
}

.Pesquisa{
    margin-top: 75px;
    text-align: center;
    margin-bottom: 50px;
}

.Filtro {
    margin-left: 10vw;
    margin-top: 4vh;
    margin-bottom: 2vh;
    margin-right: 3vw;
    font-family: 'Barlow';
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: solid 2px #000000;
    width: 20vw;
}

.BotaoFiltro {
    background-color: #002555;
    border: none;
    border-radius: 5px;
    height: 2.5em;
    color: white;
    font-weight: 800;
    width: 10em;

}

.BotaoFiltro:active{
    transform: scale(0.94);
    transition: transform 0.2s ease;
}

.BotaoFiltro:hover{
    background-color: #061c38;
}


.espacamento {
    height: 4.6875em;
}

