body {
    margin: 0;
    
    z-index: 9;
    
}

.Pesquisa{
    text-align: center;
}

.Filtro {
    margin-left: 10vw;
    margin-top: 4vh;
    margin-bottom: 2vh;
    margin-right: 3vw;
    font-family: 'Barlow';
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: solid 2px #000000;
    width: 20vw;
}

.BotaoFiltro {
    background-color: #002555;
    border: none;
    border-radius: 5px;
    height: 2.5em;
    color: white;
    font-weight: 800;
    width: 10em;
}

.BotaoFiltro:active{
    transform: scale(0.94);
    transition: transform 0.2s ease;
}

.BotaoFiltro:hover{
    background-color: #061c38;
}

.IntroducaoHomePage{
    display: flex;
    flex-flow: column;
    margin-left: 5em;

}

.VideoIbmecHome{
    margin-left: 3em;
    padding-top: 30.150px;
    padding-top: 1.884375em;
}

.VideoIbmecHome iframe{
    width: 100%;
}

.ConteudoTextosHome{
    display: block;
    margin-left: 3em;
}

.SubTituloProjetosHome{
    text-align: center;
    padding-top:  0.9375em;
}

.AlinhamentoAlunosProjetos{
    margin-left: 25%;
    align-items: center;
    justify-content: center;
}

.SubTituloAlunosHome{
    text-align: center;
}

.PerfilAlunoDetalhado{
    font-size: 2em;
}

@media screen and (min-width: 1024px){
    .IntroducaoHomePage{
        flex-flow: row;
    }
    .VideoIbmecHome iframe{
        width: 40vw;
        height:55vh;
    }
}

.IntroducaoHomePage {
    margin-top: 100px;
}